import request from '../request';

// 获取token
export function getToken(data) {
  return request('/smart-site/platform/sandieToken', {
    method: 'GET',
    body: data,
    auth: false,
  });
}
