<template>
  <div></div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import { getLocationParams } from '@/views/screen/utils/index';
import { getToken } from '@/services/screen/share';

@Component()
export default class Share extends Vue {
  created() {
    this.toSharePage();
  }
  async toSharePage() {
    const params = {
      provincePlatform: getLocationParams('provincePlatform'),
      appKey: getLocationParams('appKey'),
      secret: getLocationParams('secret'),
    };
    // 跳转到指定项目
    sessionStorage['PROJECT_ID'] && sessionStorage.removeItem('PROJECT_ID');
    if (getLocationParams('projectId')) {
      sessionStorage.setItem('PROJECT_ID', getLocationParams('projectId'));
    }
    const data = await getToken(params);
    sessionStorage['SHARE_TOKEN'] && sessionStorage.removeItem('SHARE_TOKEN');
    sessionStorage.setItem('SHARE_TOKEN', data);
    location.href = `/screen/share`;
  }
}
</script>
<style lang="less" module></style>
